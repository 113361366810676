// Generated by Framer (838580a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./WDJuu7LlC-0.js";

const enabledGestures = {q5hBTj4U3: {hover: true, pressed: true}};

const cycleOrder = ["q5hBTj4U3"];

const serializationHash = "framer-rsxMh"

const variantClassNames = {q5hBTj4U3: "framer-v-6iuied"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const numberToPixelString = (value) => {
    if (typeof value !== "number") return value;
    if (!Number.isFinite(value)) return undefined;
    return Math.max(0, value) + "px";
};


const radiusForCorner = (value, cornerIndex) => {
    if (typeof value === "number" && Number.isFinite(value)) return Math.max(0, value) + "px";
    if (typeof value !== "string" || typeof cornerIndex !== "number") return undefined;
    const segments = value.split(" ");
    return segments[cornerIndex] || segments[cornerIndex - 2] || segments[0];
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({background, click, fontSize, height, hoverColor, id, link, newTab, padding, radius, textColor, title, width, ...props}) => { return {...props, EMO0oGGig: fontSize ?? props.EMO0oGGig ?? 15, JEhVlvYOn: radius ?? props.JEhVlvYOn ?? "4px", K0NKlrzZ8: padding ?? props.K0NKlrzZ8 ?? "8px 20px 8px 20px", KQMWnrfvB: newTab ?? props.KQMWnrfvB, mTOTtH3cV: click ?? props.mTOTtH3cV, nhUxxTw81: title ?? props.nhUxxTw81 ?? "Sign up", qP4y_7qQS: textColor ?? props.qP4y_7qQS ?? "var(--token-5d8ec9b8-5e1d-4e52-86e7-69eedb287311, rgb(241, 245, 249))", VAntg4uYE: link ?? props.VAntg4uYE, xK2wYawU1: background ?? props.xK2wYawU1 ?? "rgb(41, 94, 230)", Z7pwv2t6j: hoverColor ?? props.Z7pwv2t6j ?? "rgb(59, 106, 227)"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string;newTab?: boolean;background?: string;textColor?: string;padding?: string;fontSize?: number;radius?: string;click?: any;hoverColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nhUxxTw81, VAntg4uYE, KQMWnrfvB, xK2wYawU1, qP4y_7qQS, K0NKlrzZ8, EMO0oGGig, JEhVlvYOn, mTOTtH3cV, Z7pwv2t6j, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "q5hBTj4U3", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1eyg0is = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (mTOTtH3cV) {const res = await mTOTtH3cV(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={VAntg4uYE} openInNewTab={KQMWnrfvB} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-6iuied", className, classNames)} framer-3bfc6a`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"q5hBTj4U3"} onTap={onTap1eyg0is} ref={ref ?? ref1} style={{"--xc8gxn": numberToPixelString(K0NKlrzZ8), backgroundColor: xK2wYawU1, borderBottomLeftRadius: radiusForCorner(JEhVlvYOn, 3), borderBottomRightRadius: radiusForCorner(JEhVlvYOn, 2), borderTopLeftRadius: radiusForCorner(JEhVlvYOn, 0), borderTopRightRadius: radiusForCorner(JEhVlvYOn, 1), ...style}} variants={{"q5hBTj4U3-hover": {backgroundColor: Z7pwv2t6j}, "q5hBTj4U3-pressed": {backgroundColor: Z7pwv2t6j}}} {...addPropertyOverrides({"q5hBTj4U3-hover": {"data-framer-name": undefined}, "q5hBTj4U3-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 style={{"--font-selector": "R0Y7T3BlbiBTYW5zLTcwMA==", "--framer-font-family": "\"Open Sans\", \"Open Sans Placeholder\", sans-serif", "--framer-font-size": "calc(var(--variable-reference-EMO0oGGig-WDJuu7LlC) * 1px)", "--framer-font-weight": "700", "--framer-letter-spacing": "0px", "--framer-text-color": "var(--extracted-a0htzi, var(--variable-reference-qP4y_7qQS-WDJuu7LlC))"}}>Sign up</motion.h3></React.Fragment>} className={"framer-vwgsx1"} data-framer-name={"Sign up"} fonts={["GF;Open Sans-700"]} layoutDependency={layoutDependency} layoutId={"j5CKpP0uZ"} style={{"--extracted-a0htzi": "var(--variable-reference-qP4y_7qQS-WDJuu7LlC)", "--framer-paragraph-spacing": "0px", "--variable-reference-EMO0oGGig-WDJuu7LlC": EMO0oGGig, "--variable-reference-qP4y_7qQS-WDJuu7LlC": qP4y_7qQS}} text={nhUxxTw81} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rsxMh.framer-3bfc6a, .framer-rsxMh .framer-3bfc6a { display: block; }", ".framer-rsxMh.framer-6iuied { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: var(--xc8gxn); position: relative; text-decoration: none; width: min-content; }", ".framer-rsxMh .framer-vwgsx1 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rsxMh.framer-6iuied { gap: 0px; } .framer-rsxMh.framer-6iuied > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-rsxMh.framer-6iuied > :first-child { margin-left: 0px; } .framer-rsxMh.framer-6iuied > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 94.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"VvcFd953U":{"layout":["auto","auto"]},"qY8R9qx6g":{"layout":["auto","auto"]}}}
 * @framerVariables {"nhUxxTw81":"title","VAntg4uYE":"link","KQMWnrfvB":"newTab","xK2wYawU1":"background","qP4y_7qQS":"textColor","K0NKlrzZ8":"padding","EMO0oGGig":"fontSize","JEhVlvYOn":"radius","mTOTtH3cV":"click","Z7pwv2t6j":"hoverColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWDJuu7LlC: React.ComponentType<Props> = withCSS(Component, css, "framer-rsxMh") as typeof Component;
export default FramerWDJuu7LlC;

FramerWDJuu7LlC.displayName = "Button";

FramerWDJuu7LlC.defaultProps = {height: 34, width: 94.5};

addPropertyControls(FramerWDJuu7LlC, {nhUxxTw81: {defaultValue: "Sign up", displayTextArea: false, title: "Title", type: ControlType.String}, VAntg4uYE: {title: "Link", type: ControlType.Link}, KQMWnrfvB: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}, xK2wYawU1: {defaultValue: "rgb(41, 94, 230)", title: "Background", type: ControlType.Color}, qP4y_7qQS: {defaultValue: "var(--token-5d8ec9b8-5e1d-4e52-86e7-69eedb287311, rgb(241, 245, 249))", title: "TextColor", type: ControlType.Color}, K0NKlrzZ8: {defaultValue: "8px 20px 8px 20px", title: "Padding", type: ControlType.Padding}, EMO0oGGig: {defaultValue: 15, title: "Font Size", type: ControlType.Number}, JEhVlvYOn: {defaultValue: "4px", title: "Radius", type: ControlType.BorderRadius}, mTOTtH3cV: {title: "Click", type: ControlType.EventHandler}, Z7pwv2t6j: {defaultValue: "rgb(59, 106, 227)", title: "HoverColor", type: ControlType.Color}} as any)

addFonts(FramerWDJuu7LlC, [{explicitInter: true, fonts: [{family: "Open Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4iY1M2xLER.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})